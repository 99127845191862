import React, { createContext, useState } from 'react';

// Create the context

export const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {

  const [userGuid, setUserGuid] = useState('');
  const [isLoggedIn, setIsloggedIn] = useState(false)
  const [apiHostUrl, setApiHostUrl] = useState('https://apisiamthai.kiwijones.com')
  const [apiKey, setApiKey] = useState('D0D49BCC-B75A-488E-9DB9-2351E97745D1')
  const [defaultSubscription, setDefaultSubscription] = useState('eeeaeb5f-572e-4b26-944d-7be5fa88e22b')

  return (
    <GlobalStateContext.Provider value={{ isLoggedIn, setIsloggedIn,userGuid, setUserGuid, apiHostUrl, setApiHostUrl,apiKey, setApiKey,defaultSubscription, setDefaultSubscription }}>
      {children}
    </GlobalStateContext.Provider>
  );
};