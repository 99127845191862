// Navbar.js
import React, { useContext, useState } from "react";
import { Link,useNavigate  } from "react-router-dom";
import "./navbar.css";
import MySVG from "../src/assets/Logos/SiamThai_Main_Logo.svg";

import { GlobalStateContext  } from "./pages/GlobalStateContext";


const Navbar = ({ isAuthenticated, onLogout }) => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); 
  const {isLoggedIn, setIsloggedIn} = useContext(GlobalStateContext)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    setMenuOpen(false);
  };

  const redirectToMainPage = () => {
    closeMobileMenu();

    window.open(
      "https://siamthai.ie/",
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const deleteCookie = (name) => {

    const domain = window.location.hostname;
    
    // Attempt deletion with various configurations
    const options = [
      `path=/;`,
      `path=/; domain=${domain};`,
      `path=/; domain=.${domain};`
    ];
  
    options.forEach(option => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${option}`;
    });
  
    // Log for debugging
    //console.log(`Tried to delete cookie: ${name}`);
  };

  const handleLogout = () => {

   // console.log("isloggedin: " + isLoggedIn )
    
    // Delete cookies
  deleteCookie('username');
  deleteCookie('password');
    

  setIsloggedIn(false)

  //console.log("isloggedin: " + isLoggedIn )
    // Additional logout actions (if any)
    
    // Redirect to login or homepage

    navigate('/login');  // Adjust the path as necessary
  };

  return (
    <nav className="navbar">
      <Link className="logo" to="/" onClick={redirectToMainPage}>
        <img src={MySVG} alt="Description of the SVG" />
      </Link>
      <div className={`nav-items ${isMenuOpen ? "open" : ""}`}>
        <Link className="nav-link" to="/" onClick={closeMobileMenu}>
          Home
        </Link>

        <Link className="nav-link" to="/login">
          Check Points
        </Link>

        {/* <Link className="nav-link" to="/mydetails">
          My Details
        </Link> */}

        {/* only if the user is logged in will they see the profile menu option */}
        {isLoggedIn ? (
          <>
          <Link className="nav-link" to="/profile">
              Profile
            </Link>
            
            <Link className="nav-link" to="/login" onClick={handleLogout}> 
            Logout
            </Link>
          </>
        ) : (
          <>
            <Link className="nav-link" to="/register">
              Register
            </Link>
            </>
        )}
            
      
      </div>
      <div className="hamburger" onClick={toggleMenu} onMouseOver={toggleMenu}>
        <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"} />
      </div>
    </nav>
  );
};

export default Navbar;
