import React, { useEffect, useState,useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../pages/GlobalStateContext"
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

//const apiUrl = "HTTPS://siamapi.azurewebsites.net"

const SECRET_KEY = 'Qa8cJQhxqj7Z69bKFScxpocudiBwyOPPT4i3Ak1WkK3I';

function LoginForm() {
   
  const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);
  const { userGuid, setUserGuid } = useContext(GlobalStateContext);
  const {isLoggedIn, setIsloggedIn} = useContext(GlobalStateContext);
  const {apiKey, setApiKey} = useContext(GlobalStateContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [navAway, setNavAway] = useState(false);

  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const myHeaders = new Headers();

  const [showPassword, setShowPassword] = useState(false);
  const [os, setOS] = useState('');  

  const [userInfo, setUserInfo] = useState({
    os: '',
    browser: '',
    deviceType: '',
    platform: '',
    language: '',
  });

  const getDecryptedData = (key) => {
    const encryptedData = Cookies.get(key);
    if (!encryptedData) return '';
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {


    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const language = window.navigator.language;

    // Detect OS
    let os = 'Unknown';
    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      os = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      os = 'Android';
    } else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
      os = 'iOS';
    }

    // Detect Browser
    let browser = 'Unknown';
    if (userAgent.indexOf('Chrome') !== -1 && userAgent.indexOf('Safari') !== -1) {
      browser = 'Chrome';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      browser = 'Firefox';
    } else if (userAgent.indexOf('Safari') !== -1) {
      browser = 'Safari';
    } else if (userAgent.indexOf('Edge') !== -1) {
      browser = 'Edge';
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
      browser = 'Internet Explorer';
    }

    // Detect Device Type
    let deviceType = 'Desktop';
    if (/Mobi|Android/i.test(userAgent)) {
      deviceType = 'Mobile';
    } else if (/iPad|Tablet/i.test(userAgent)) {
      deviceType = 'Tablet';
    }

    // Set the state with gathered info
    setUserInfo({
      os,
      browser,
      deviceType,
      platform,
      language,
    });
  }, []);

  myHeaders.append("Accept", "text/plain");
  myHeaders.append("X-API-KEY", apiKey); // Replace with your actual API key

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  
  };

  useEffect(() => {

   


    const savedUsername = getDecryptedData('username');
    const savedPassword = getDecryptedData('password');
  
    // console.log("Cookie:", savedUsername);
    // console.log("Cookie:", savedPassword);

    // console.log("isLoggedIn: " + isLoggedIn)

    if (savedUsername.length > 3 && savedPassword.length > 2) {
      setUsername(savedUsername);
      setPassword(savedPassword);
    }
    else{
      //console.log("settong pass word tpo nothng")
      setUsername("");
      setPassword("");
    }

    
  }, []); // Empty dependency array to run this effect only once on mount
  
  // This useEffect will run after username and password have updated
  useEffect(() => {

    if (isLoggedIn){

      if (username && password) {
        // console.log("Username C (updated):", username);
        // console.log("Password C (updated):", password);
        
        // Call callHandleSubmit once username and password are set
        //callHandleSubmit();
      }
    }
  
  }, [username, password]); 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const callHandleSubmit = () => {
    // Create a mock event to pass to handleSubmit
    const mockEvent = { preventDefault: () => {} }; // preventDefault is added to mimic event behavior
    handleSubmit(mockEvent);
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("--- handleSubmit ---")
    // console.log("isLoggedIn: " + isLoggedIn)
    setLoading(true);

    // Here you can perform authentication logic
    // console.log("Username:", username);
    // console.log("Password:", password);

    // Reset form fields
    //setUsername("");
    //setPassword("");

    //setUserGuid("hello");

    let url =
    apiHostUrl + "/user/Login/" +
      username +
      "/" +
      password +
      "";

      //console.log(apiKey)
      //console.log(url)

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {

        //console.log(result)

        if (result.includes('Not')) {

          toast.warn("Invalid login");

          setNavAway(true);

        } else {
          
          
          const encryptedUsername = CryptoJS.AES.encrypt(username, SECRET_KEY).toString();
          const encryptedPassword = CryptoJS.AES.encrypt(password, SECRET_KEY).toString();

          Cookies.set('username', encryptedUsername, { expires: 365 }); 
          Cookies.set('password', encryptedPassword, { expires: 365 }); 

          toast.success("Login OK");

          setAuth(true);

          setIsloggedIn(true)
          
          //console.log(result)



          sessionStorage.setItem("userId", result);
         
          setUserGuid(result);
             
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("X-API-KEY", apiKey)

          const raw = JSON.stringify({
            "userId": result,
            "os": userInfo.os,
            "browser": userInfo.browser,
            "deviceType": userInfo.deviceType,
            "platform": userInfo.platform,
            "language": userInfo.language
          });
          
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
           
          };
          
          var url = apiHostUrl + "/user/UserVisit";

          //console.log(url)

          fetch(url, requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));

        }

        setLoading(false);
      })
      .catch((error) => {
        //console.error(error);

        toast.warning(error);
      });
  };

  return (
    <>
    
      {navAway ? <Navigate to="/" /> : ""}

      {auth ? (
        <Navigate to="/mydetails" />
      ) : (
        <div className="container_2">
          {loading ? <img src="../Small_Spinner.svg" /> : ""}

          <form onSubmit={handleSubmit}>
            <div>
              <h3>RM Loyalty</h3>
              {/* <label htmlFor="username">Username:</label> */}
              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="number"
                id="username"
                value={username}
                placeholder="Phone Number"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              
            </div>
            <div  style={{ position: "relative", display: "inline-block" }}>
              {/* <label htmlFor="password">Password:</label> */}
              <input
                style={{ margin: "3px", paddingRight: "30px" }}
                className="custom-input"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
                
              />
              <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}

                </span>
            </div>
            <button
              className="primary-button"
              style={{ margin: "3px" }}
              type="submit"
            >
              Login
            </button>

            <div>
              <Link to="/forgot">
                <button className="secondary-button" style={{ margin: "3px" }}>
                  Reset password
                </button>
              </Link>
            </div>

            <div></div>
          </form>
        </div>
      )}
    </>
  );
}

export default LoginForm;
